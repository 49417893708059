.clusterPicture {
  position: relative;
  max-height: 600px;
  overflow: hidden;

  &:hover .clusterPictureImage {
    transform: scale(1); // Slightly larger to give a zoom effect
  }

  .clusterPictureImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    transition: transform 0.5s ease; // Faster and using transform
    will-change: transform; // Prepares the browser for the transformation
  }
  .btn-generate {
    position: absolute;
    bottom: 20px;
    left: 140px;
    opacity: 80%;

    &:hover {
      opacity: 1 !important;
    }
  }

  .magazine-container {
    position: relative;
    width: 500px;
    height: 500px;
  }

  .magazine-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay-text {
    position: absolute;
    top: 29%;
    left: 48%;
    font-family: "Georgia", serif;
    transform: translate(-40%, -40%) rotate(-31deg) skewX(3deg);
    opacity: 0.7;
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    max-width: 155px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    pointer-events: none;
  }
}
