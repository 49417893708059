.collapsibleSegment {
  .segmentHeader {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .segmentContent {
    padding: 10px 0px;
    overflow: hidden;
    position: relative;

    &.preview {
      max-height: 400px;
      overflow: hidden;

      .gradientOverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 140px;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
        display: flex;
        justify-content: center;
        align-items: center;
        //no pointer events
        pointer-events: none;
      }

      .showMoreButton {
        pointer-events: auto;
        margin-bottom: -120px;
        background: none;
        border: none;
        color: #007bff;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .collapseIndicator {
    font-size: 18px;
    margin-left: 10px;
  }
}
