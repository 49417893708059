@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.maplibregl-popup {
  opacity: 0; /* Ensure it starts hidden */
  animation: fadeIn 200ms ease-in-out forwards; /* Fade-in animation */
}

.maplibregl-popup-content {
  //ignore all mouse events
  pointer-events: none !important;
  width: 300px !important;
  background-color: #ffffff !important;
  padding: 10px !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15) !important;
  border-color: #202124 !important;
  padding: 0 !important;
}

.popupHoverCluster {
  .clusterImage {
    width: 100%;
    height: 105px;
    object-fit: cover;
    margin-bottom:-10px;
  }
  .clusterContent {
    padding: 10px 10px 5px 10px;

    .clusterHeader {
      color: #4f5158;
      font-weight: normal;
      text-align: left;
      font-size: 11px;
    }

    .clusterLabel {
      text-align: left;
      font-weight: bold;
      font-size: 16px;
    }

    .clusterDescription {
      margin-top: 5px;
      text-align: left;
      font-size: 12x;
    }
  }
}

.popupHoverPaper {
  padding: 10px 10px 5px 10px;

  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .paperHeader {
    color: #4f5158;
    font-weight: normal;
    text-align: left;
    font-size: 11px;
  }

  .paperTypeLine {
    height: 3px;
    width: 40px;
    margin-bottom: 5px;
  }

  .paperTitle {
    text-align: left;
    font-weight: bold;
    color: #202124;

    line-height: 1.2;
  }

  .meta {
    margin-top: 5px;
    color: #70757a;

    .metaRow {
      display: flex;
      flex-direction: row;
      gap: 5px;
      text-align: left;
    }
  }

  .skeleton-text {
    background-color: rgba(0, 0, 0, 0.11);
  }
}

.hoverInfo {
  font-size: 10px;
  color: #1a73e8;
  text-align: left;
  margin-bottom: 5px;
}
