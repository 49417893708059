.authorDisplay {
  .author {
    font-size:14px;
    color: rgba(55, 55, 238, 0.836);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
