.sidebarToggleButton{
    background-color: white;
    border:1px solid lightgrey;
    position: absolute;
    top: 50%;
    z-index: 3000;
    &:hover{
        cursor:pointer;
        border:1px solid grey;
    }
}