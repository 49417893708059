.clusterPanel {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 400px;
  display: flex;
  flex-direction: column;
  z-index: 3;
  box-shadow: 1px 1px 4px #c5c5c5;
  background-color: white;

  .clusterContainer {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    padding-top:0px;
    text-align: left;

    .clusterTopWord {
      display: inline-block;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 10px;
      background-color: #ffecec;
      margin-right: 3px;
      font-size: 12px;
      margin-top: 3px;
    }

    .clusterHeader {
      font-size: 22px;
      font-weight: bold;
      padding: 5px 0px 5px 0px;
    }

  
  }
}
