.clusterPreviewBatch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 5px;
  padding: 10px;
  border: 1px solid #ebebeb;
  //border-radius: 5px;
  background-color: #ffffff;
  font-size: 12px;
  text-align: left;
  width: calc(100% - 20px);
  overflow: hidden;

  &:hover {
    background-color: #e7eced;
    cursor: pointer;
  }

  svg{
    color:rgb(54, 54, 54);
    font-size:14px;
  }
}
