/* ClusterHoverPictureDisplay.scss */

.clusterHoverPicture {
    width: 100%;
    height: 105px;
    object-fit: cover;
    margin-bottom:-10px;

    position: relative;
    overflow: hidden;
    background-color: #f0f0f0;



.skeletonContainer {
    position: relative;
    width: 100%;
    height: 105px; /* Match the Skeleton height */
  }
  
  .clusterHoverSkeleton {
    width: 100%;
    height: 100%;
  }
  
  .clusterHoverPictureImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .fakeProgress {
    position: absolute;
    height:3px;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  }
  
  .clusterHoverSkeleton {
    width: 100%;
    height: 100%;
  }
  
  .clusterHoverPictureImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .clusterHoverOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
  }

  