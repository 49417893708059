.paperPicture {
    position: relative;
    max-height: 600px;
    overflow: hidden;

    &:hover .paperPictureImage {
        transform: scale(1.0); // Slightly larger to give a zoom effect
    }
    
    .paperPictureImage {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center;
        transition: transform 0.5s ease; // Faster and using transform
        will-change: transform; // Prepares the browser for the transformation
    }
    .btn-generate {
        position: absolute;
        bottom: 20px;
        left: 140px;
        opacity:80%;
      
      &:hover{
        opacity:1 !important;
      }
    }
    .overlay-text {
      position: absolute;
      top: 8%;
      left: 97px;
      font-family: "Georgia", serif;
      /* transform: translate(0, 0) rotate(0deg) skewX(5deg); */
      color: #393939;
      font-size: 11px;
      font-weight: bold;
      max-width: 200px;
      white-space: normal;
      word-wrap: normal;
      text-align: left;
      pointer-events: none;
  }
}
